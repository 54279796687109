<template>
  <div>
    <div id="app" class="app-body">
      <div class="p-3">
        <div class="spacer-small"></div>
        <div class="columns">
          <div class="column">
            <p class="is-subtitle is-secondary is-bold">Team Workspace</p>
            <p v-if="currentUser" class="mt-5">
              Hello! {{ currentUser.fullName }},
            </p>
            <p>Welcome back to your Brandeeq workspace</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="spacer-small"></div>
            <div v-if="loading">
              <Loading />
            </div>
            <div v-if="activeOrders && !loading" class="box">
              <b-table
                v-if="activeOrders.length"
                :data="activeOrders"
                :per-page="10"
                :striped="true"
                :hoverable="true"
              >
                <b-table-column
                  field="project"
                  label="Project"
                  sortable
                  v-slot="props"
                >
                  <b-tag
                    v-if="props.row.projectName"
                    :style="
                      props.row.projectColor
                        ? getProjectTagStyle(props.row.projectColor)
                        : ''
                    "
                    class="my-2"
                    type="is-warning"
                    size="is-small"
                  >
                    {{ props.row.projectName || "Uncategorized" }}
                  </b-tag>
                  <span v-else>-</span>
                </b-table-column>

                <b-table-column field="service" label="Service" v-slot="props">
                  {{ props.row.service.name }}
                </b-table-column>

                <b-table-column field="status" label="Status" v-slot="props">
                  <b-tag :type="getStatusTagColor(props.row.status)">
                    {{ props.row.status | status("vendor") }}
                  </b-tag>
                </b-table-column>

                <!-- <b-table-column field="dueDate" label="Due Date">
                  <p>{{ new Date().toISOString() | date }}</p>
                </b-table-column> -->

                <b-table-column label="" v-slot="props">
                  <router-link
                    v-if="
                      props.row.status != 'processingPayment' &&
                      props.row.status != 'submitQuestionnaire'
                    "
                    :to="{
                      name: 'VendorOrderDetail',
                      query: { orderId: props.row._id },
                    }"
                  >
                    <i class="mdi mdi-eye mr-1"></i>
                    View Details
                  </router-link>
                </b-table-column>
              </b-table>
              <p v-else>
                <i class="mdi mdi-information-outline mr-1"></i>
                No active orders at the moment
              </p>
            </div>

            <!-- Modals Start -->
            <b-modal v-model="showDeliverableModal" has-modal-card>
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="is-subtitle-small is-dark-gray is-bold">
                    Task Delivery
                  </p>
                </header>

                <section class="modal-card-body has-text-centered">
                  <p>Please upload your completed tasks in .pdf format.</p>
                  <b-field class="mt-4">
                    <b-upload v-model="dropFiles" drag-drop multiple>
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="upload" size="is-large"> </b-icon>
                          </p>
                          <p>Drop your files here or click to upload</p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>

                  <div class="tags">
                    <span
                      v-for="(file, index) in dropFiles"
                      :key="index"
                      class="tag is-primary"
                    >
                      {{ file.name }}
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                      ></button>
                    </span>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <b-button
                    label="Cancel"
                    @click="closeDeliverableUploadModal"
                  />
                  <b-button
                    type="is-info"
                    :disabled="isSubmitting"
                    @click="submitDeliverable"
                  >
                    {{ isSubmitting ? "Submitting" : "Submit" }}
                  </b-button>
                </footer>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";

export default {
  name: "VendorDashboard",
  components: { Loading },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      showDeliverableModal: false,
      dropFiles: [],
      orderSelected: undefined,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("getAllServices");
    await this.$store.dispatch("getAllVendorOrders");
    this.loading = false;
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    allServices() {
      return this.$store.state.allServices;
    },
    activeOrders() {
      return this.$store.state.allVendorOrders;
    },
    completedServices() {
      return undefined;
    },
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    openDeliverableUploadModal(orderId) {
      this.showDeliverableModal = !this.showDeliverableModal;
      this.orderSelected = orderId;
    },
    closeDeliverableUploadModal() {
      this.showDeliverableModal = false;
    },
    getProjectTagStyle(bgColor) {
      // calculating text color to be black or white depending on the background color
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      const textColor =
        r * 0.299 + g * 0.587 + b * 0.114 > 150 ? "#000000" : "#FFFFFF";

      const style = bgColor
        ? "background-color: " +
          bgColor +
          " !important; color: " +
          textColor +
          " !important"
        : "";

      return style;
    },
    getStatusTagColor(status) {
      if (status == "needsAttention" || status == "newUpdate") {
        return "is-warning";
      } else if (status == "inProgress") {
        return "is-danger";
      } else if (status == "completed") {
        return "is-info";
      } else {
        return "is-light";
      }
    },
    async submitDeliverable() {
      this.isSubmitting = true;
      const metadata = JSON.stringify({ orderId: this.orderSelected });
      const request = new FormData();
      request.append("file", this.dropFiles[0]);
      request.append("type", "deliverable");
      request.append("metadata", metadata);

      await this.$store.dispatch("uploadDeliverable", request);
      this.closeDeliverableUploadModal();
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
