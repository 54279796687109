var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-body",attrs:{"id":"app"}},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"spacer-small"}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"is-subtitle is-secondary is-bold"},[_vm._v("Team Workspace")]),(_vm.currentUser)?_c('p',{staticClass:"mt-5"},[_vm._v(" Hello! "+_vm._s(_vm.currentUser.fullName)+", ")]):_vm._e(),_c('p',[_vm._v("Welcome back to your Brandeeq workspace")])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"spacer-small"}),(_vm.loading)?_c('div',[_c('Loading')],1):_vm._e(),(_vm.activeOrders && !_vm.loading)?_c('div',{staticClass:"box"},[(_vm.activeOrders.length)?_c('b-table',{attrs:{"data":_vm.activeOrders,"per-page":10,"striped":true,"hoverable":true}},[_c('b-table-column',{attrs:{"field":"project","label":"Project","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.projectName)?_c('b-tag',{staticClass:"my-2",style:(props.row.projectColor
                      ? _vm.getProjectTagStyle(props.row.projectColor)
                      : ''),attrs:{"type":"is-warning","size":"is-small"}},[_vm._v(" "+_vm._s(props.row.projectName || "Uncategorized")+" ")]):_c('span',[_vm._v("-")])]}}],null,false,967822962)}),_c('b-table-column',{attrs:{"field":"service","label":"Service"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.service.name)+" ")]}}],null,false,492199561)}),_c('b-table-column',{attrs:{"field":"status","label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tag',{attrs:{"type":_vm.getStatusTagColor(props.row.status)}},[_vm._v(" "+_vm._s(_vm._f("status")(props.row.status,"vendor"))+" ")])]}}],null,false,1526109294)}),_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(
                    props.row.status != 'processingPayment' &&
                    props.row.status != 'submitQuestionnaire'
                  )?_c('router-link',{attrs:{"to":{
                    name: 'VendorOrderDetail',
                    query: { orderId: props.row._id },
                  }}},[_c('i',{staticClass:"mdi mdi-eye mr-1"}),_vm._v(" View Details ")]):_vm._e()]}}],null,false,1918557480)})],1):_c('p',[_c('i',{staticClass:"mdi mdi-information-outline mr-1"}),_vm._v(" No active orders at the moment ")])],1):_vm._e(),_c('b-modal',{attrs:{"has-modal-card":""},model:{value:(_vm.showDeliverableModal),callback:function ($$v) {_vm.showDeliverableModal=$$v},expression:"showDeliverableModal"}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"is-subtitle-small is-dark-gray is-bold"},[_vm._v(" Task Delivery ")])]),_c('section',{staticClass:"modal-card-body has-text-centered"},[_c('p',[_vm._v("Please upload your completed tasks in .pdf format.")]),_c('b-field',{staticClass:"mt-4"},[_c('b-upload',{attrs:{"drag-drop":"","multiple":""},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your files here or click to upload")])])])])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index)}}})])}),0)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":"Cancel"},on:{"click":_vm.closeDeliverableUploadModal}}),_c('b-button',{attrs:{"type":"is-info","disabled":_vm.isSubmitting},on:{"click":_vm.submitDeliverable}},[_vm._v(" "+_vm._s(_vm.isSubmitting ? "Submitting" : "Submit")+" ")])],1)])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }